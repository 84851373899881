
import { defineComponent, ref, computed, onMounted } from 'vue';
import { ChartData, ChartConfiguration, ChartType } from 'chart.js';
import Chart from 'chart.js/auto';
import _ from 'lodash';

export interface ChartDataType {
    value: number;
    title: string;
    tooltip: string;
}

const crmPallete = [
    '#F68C1E',
    '#087180',
    '#0DE1FF',
    '#CC2B95',
    '#6637B6',
    '#DB3D10',
    '#37B638',
    '#31B8F4',
    '#FFD232',
    '#FC4B6C',
    '#363F46',
    '#8A0159'
];
const bookitPallete = ['#FFBD43', '#F1354C', '#1FB6FF', '#F032AF', '#78CC19', '#A704D0', '#093EC5'];
const bookitIndexStatsPallete = [
    { colorStart: '#F17306', colorEnd: '#F68C1F' },
    { colorStart: '#7046BB', colorEnd: '#734BBD' },
    { colorStart: '#2C9CE5', colorEnd: '#1B78D3' },
    { colorStart: '#302CE5', colorEnd: '#18167B' },
    { colorStart: '#2CE58C', colorEnd: '#056839' },
    { colorStart: '#FE4A8B', colorEnd: '#6C1132' },
    { colorStart: '#BB6246', colorEnd: '#713421' },
    { colorStart: '#E1E52C', colorEnd: '#686A0E' },
    { colorStart: '#F05E78', colorEnd: '#61111F' },
    { colorStart: '#0BC7D9', colorEnd: '#26686F' }
];

export default defineComponent({
    components: {},
    name: 'PieBlock',
    data() {
        return {};
    },
    props: {
        id: [String, Number],
        data: {
            type: Array,
            required: true
        },
        name: String,
        zoomed: String,
        type: {
            type: String,
            default: 'pie'
        },
        showTooltip: {
            type: Boolean,
            default: true
        },
        showLegend: {
            type: Boolean,
            default: true
        },
        titleLegenText: {
            type: String,
            default: ''
        },
        showTitleLegend: {
            type: Boolean,
            default: false
        },
        showTooltipValue: {
            type: Boolean,
            default: true
        },
        showTitles: {
            type: Boolean,
            default: false
        },
        redrawOnData: {
            type: Boolean,
            default: false
        },
        pallete: {
            type: Array,
            default: () => bookitPallete
        },
        isGradient: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        data: function(value, old) {
            if (!this.redrawOnData && JSON.stringify(value) == JSON.stringify(old)) return;
            // this.data = value || [];
            this.drawPie();
        },
        type(val) {
            this.drawPie(true);
        }
    },
    async setup(props) {
        const el = ref<HTMLElement | null>(null);

        let pieInternal: Chart | null = null;

        function chartData() {
            return props.data as ChartDataType[];
        }
        const total = computed(() => {
            return _.sum(chartData().map(x => x.value));
        });
        const columnsLabel = computed(() => {
            return chartData().map(x => _.round((100.0 * x.value) / total.value, 2) + '% - ' + (x.tooltip || x.value.toLocaleString('he')));
        });

        function drawPie(full = false) {
            const ctx = (el.value as any).getContext('2d');
            const data: ChartData = {
                labels: [],
                datasets: []
            };

            const gradientColors = () => {
                const gradients: CanvasGradient[] = [];
                bookitIndexStatsPallete.forEach((x, index) => {
                    const gradient = ctx.createLinearGradient(0,0,0,90);

                    gradient.addColorStop(0, bookitIndexStatsPallete[index].colorStart as string);
                    gradient.addColorStop(1, bookitIndexStatsPallete[index].colorEnd as string);

                    gradients.push(gradient);
                });
                return gradients;
            }

            const type = (props.type || 'pie').toLowerCase() as ChartType;
            switch (type) {
                case 'bar':
                    data.datasets = chartData().map((x, i) => ({
                        data: [x.value],
                        label: x.title,
                        categoryPercentage: 1,
                        backgroundColor: props.pallete[i % props.pallete.length] as string
                    }));
                    break;
                case 'pie':
                case 'doughnut':
                default:
                    data.labels = chartData().map(x => x.title || x.tooltip);
                    data.datasets.push({
                        data: chartData().map(x => x.value),
                        backgroundColor: props.isGradient ? gradientColors() : props.pallete as string[]
                    });
                    break;
            }
            if (full && pieInternal != null) {
                pieInternal.destroy();
                pieInternal = null;
            }
            if (pieInternal == null) {
                const config: ChartConfiguration = {
                    type,
                    data,
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                bottom: type == 'bar' ? 0 : 10
                            }
                        },

                        plugins: {
                            legend: {
                                display: props.showLegend,
                                position: 'right',
                                align: 'start',
                                title: {
                                    display: props.showTitleLegend,
                                    position: 'start',
                                    text: props.titleLegenText,
                                    color: '#332F4B',

                                    padding: {
                                        bottom: 5,
                                        left: 50,
                                    },
                                    font: {
                                        family: 'CircularStd-Medium, Heebo-Medium',
                                        size: 16,
                                        weight: 'bold',
                                    }
                                },
                                labels: {
                                    color: '#332F4B',
                                    boxWidth: 6,
                                    usePointStyle: true,
                                    font: {
                                        family: 'CircularStd-Medium, Heebo-Medium'
                                    }
                                }
                            },
                            tooltip: {
                                enabled: props.showTooltip,
                                callbacks: {
                                    title: context => {
                                        if (!props.showTooltipValue) return '';
                                        let title = '';

                                        if (context.length > 0) {
                                            const item = context[0];
                                            const pos = Number(props.type == 'bar' ? item.datasetIndex : item.dataIndex);
                                            if (pos < columnsLabel.value.length) {
                                                title = columnsLabel.value[pos];
                                            } else {
                                                const value = Number(data.datasets[Number(item.datasetIndex)].data[Number(item.dataIndex)]);
                                                title = _.round((100.0 * value) / total.value, 2) + '% - ' + value.toLocaleString('he');
                                            }
                                        }

                                        return title;
                                    },

                                    label: context => {
                                        context.datasetIndex;
                                        let label = '';
                                        const labels = data.labels;
                                        const labelCount = labels ? labels.length : 0;
                                        if (data.datasets[Number(context.datasetIndex)].label) {
                                            label = data.datasets[Number(context.datasetIndex)].label || '';
                                        } else if (context.label) {
                                            label = context.label;
                                        } else if (
                                            labels &&
                                            labelCount > 0 &&
                                            context.dataIndex !== undefined &&
                                            context.dataIndex < labelCount
                                        ) {
                                            label = String(labels[context.dataIndex]);
                                        }
                                        return label;
                                    }
                                }
                            }
                        }
                    }
                };
                switch (type) {
                    case 'doughnut':
                        (config.options as any).cutout = '35%';
                        //if (props.showTitles) config.plugins = [ChartsPlugins.PieTitlePlugin];
                        break;
                    case 'pie':
                        //if (props.showTitles) config.plugins = [ChartsPlugins.PieTitlePlugin];
                        break;
                    default:
                        break;
                }
                pieInternal = new Chart(ctx, config);
            } else {
                pieInternal.data = data;
                pieInternal.update();
                //return;
            }
        }

        onMounted(async () => {
            drawPie();
        });
        return { el, drawPie };
    }
});
